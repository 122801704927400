
import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";

export const NaePaths = [
    {
        "id": "pingtest",
        "method": "get",
        "path": "/app/tmp/ping",
        "parameters": []
    },
    {
        "id": "AnalyticsCustomList",
        "method": "post",
        "path": "/app/plugins/AnalyticsCustom/list",
        "parameters": []
    },
    {
        "id": "AnalyticsCustomListV2",
        "method": "post",
        "path": "/app/plugins/AnalyticsCustom/analyticsCustomListV2",
        "parameters": []
    },
    {
        "id": "DashboardAccountingCalculate",
        "method": "post",
        "path": "/app/plugins/DashboardAccounting/calculate",
        "parameters": []
    },
    {
        "id": "GrossCalculateV2",
        "method": "post",
        "path": "/app/plugins/Dashboard/calculateV2",
        "parameters": []
    },
    {
        "id": "pluginsDashboardChartStats",
        "method": "post",
        "path": "/app/plugins/Dashboard/chartStats",
        "parameters": []
    },
    {
        "id": "pluginsDashboardStats",
        "method": "post",
        "path": "/app/plugins/Dashboard/stats",
        "parameters": []
    },
    {
        "id": "pluginsDashboardSlugsGrossByOrderItems",
        "method": "post",
        "path": "/app/plugins/Dashboard/getGrossByOrderItems",
        "parameters": []
    },
    {
        "id": "pluginsDashboardSlugsGrossByOrderItemsDate",
        "method": "post",
        "path": "/app/plugins/Dashboard/getGrossByOrderItemsDate",
        "parameters": []
    },
    {
        "id": "pluginsDashboardSubsStopDateReport",
        "method": "post",
        "path": "/app/plugins/Dashboard/getSubsStopDateReport",
        "parameters": []
    },
    {
        "id": "DashboardDevStats",
        "method": "post",
        "path": "/app/plugins/DashboardDev/stats",
        "parameters": []
    },
    {
        "id": "pluginsDashboardCalcPlanPerformance",
        "method": "post",
        "path": "/app/plugins/PlanPerformance/calcPlanPerformance",
        "parameters": []
    },
    {
        "id": "ProductPaybackCalculate",
        "method": "post",
        "path": "/app/plugins/ProductPayback/calculate",
        "parameters": []
    },
    {
        "id": "ProductPerformanceCalculateV2",
        "method": "post",
        "path": "/app/plugins/ProductPerformance/calculateV2",
        "parameters": []
    },
    {
        "id": "ProductPerformanceRefundsData",
        "method": "post",
        "path": "/app/plugins/ProductPerformance/refundsData",
        "parameters": []
    },
    {
        "id": "ProductPerformanceRefundsCountData",
        "method": "post",
        "path": "/app/plugins/ProductPerformance/refundsCountData",
        "parameters": []
    },
    {
        "id": "RecurringControllerCalculate",
        "method": "post",
        "path": "/app/plugins/Recurring/calculate",
        "parameters": []
    },
    {
        "id": "SubPrefixList",
        "method": "post",
        "path": "/app/plugins/SubPrefix/list",
        "parameters": []
    },
    {
        "id": "KlavyioControllerGetProfile",
        "method": "post",
        "path": "/app/plugins/Klavyio/getProfile",
        "parameters": []
    },
    {
        "id": "KlavyioControllerReady",
        "method": "post",
        "path": "/app/plugins/Klavyio/ready",
        "parameters": []
    },
    {
        "id": "KlavyioControllerRun",
        "method": "post",
        "path": "/app/plugins/Klavyio/run",
        "parameters": []
    },
    {
        "id": "pluginsQuizResultStatExport",
        "method": "post",
        "path": "/app/plugins/QuizResultStat/export",
        "parameters": []
    },
    {
        "id": "pluginsReportItemResyncOrderItems",
        "method": "post",
        "path": "/app/plugins/ReportItem/resyncOrderItems",
        "parameters": []
    },
    {
        "id": "pluginsReportItemResyncOrderItemsForceSub",
        "method": "post",
        "path": "/app/plugins/ReportItem/resyncOrderItemsForceSub",
        "parameters": []
    },
    {
        "id": "pluginsReportItemResyncReportItem",
        "method": "post",
        "path": "/app/plugins/ReportItem/resyncReportItem",
        "parameters": []
    },
    {
        "id": "reportsFilterPeriods",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/periods",
        "parameters": []
    },
    {
        "id": "reportsFilterSlugs",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/slugs",
        "parameters": []
    },
    {
        "id": "reportsFilterAdSource",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/distintAdSources",
        "parameters": []
    },
    {
        "id": "reportsFilterAdSourceLevel2",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/distintAdSourcesL2",
        "parameters": []
    },
    {
        "id": "reportsFilterAdSourceAdopted",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/distintAdSourcesAdopted",
        "parameters": []
    },
    {
        "id": "reportsFilterPlanSku",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/distintPlanSku",
        "parameters": []
    },
    {
        "id": "reportsFilterLanguage",
        "method": "post",
        "path": "/app/plugins/Reports/ReportsFilter/distinctLanguage",
        "parameters": []
    },
    {
        "id": "SubscriptionPaymentDoRecurring",
        "method": "post",
        "path": "/app/plugins/SubscriptionPayment/doRecurring",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudCarServiceCountries",
        "method": "post",
        "path": "/app/plugins/Tags/countries",
        "parameters": []
    },
    {
        "id": "AppPluginsTagCloudSubsCancel",
        "method": "post",
        "path": "/app/plugins/Tags/subsCancel",
        "parameters": []
    },
    {
        "id": "NAEauthGetProfile",
        "method": "post",
        "path": "/app/nae-core/auth/get",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderList",
        "method": "post",
        "path": "/app/nae-core/config-builder/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderSave",
        "method": "post",
        "path": "/app/nae-core/config-builder/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderRemove",
        "method": "post",
        "path": "/app/nae-core/config-builder/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsList",
        "method": "post",
        "path": "/app/nae-core/config-defaults/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsSave",
        "method": "post",
        "path": "/app/nae-core/config-defaults/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsRemove",
        "method": "post",
        "path": "/app/nae-core/config-defaults/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditList",
        "method": "post",
        "path": "/app/nae-core/config-edit/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditSave",
        "method": "post",
        "path": "/app/nae-core/config-edit/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditRemove",
        "method": "post",
        "path": "/app/nae-core/config-edit/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabList",
        "method": "post",
        "path": "/app/nae-core/config-tabs/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabSave",
        "method": "post",
        "path": "/app/nae-core/config-tabs/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabRemove",
        "method": "post",
        "path": "/app/nae-core/config-tabs/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewList",
        "method": "post",
        "path": "/app/nae-core/config-view/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewSave",
        "method": "post",
        "path": "/app/nae-core/config-view/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewRemove",
        "method": "post",
        "path": "/app/nae-core/config-view/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetList",
        "method": "post",
        "path": "/app/nae-core/config-widgets/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetSave",
        "method": "post",
        "path": "/app/nae-core/config-widgets/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetRemove",
        "method": "post",
        "path": "/app/nae-core/config-widgets/removeConfig",
        "parameters": []
    },
    {
        "id": "NAEUExport",
        "method": "post",
        "path": "/app/nae-core/export/doExport",
        "parameters": []
    },
    {
        "id": "NAEfileRemove",
        "method": "post",
        "path": "/app/nae-core/files/remove",
        "parameters": []
    },
    {
        "id": "NAEfilesList",
        "method": "post",
        "path": "/app/nae-core/files/list",
        "parameters": []
    },
    {
        "id": "NAEfilesMultipleList",
        "method": "post",
        "path": "/app/nae-core/files/multipleList",
        "parameters": []
    },
    {
        "id": "NAEUImport",
        "method": "post",
        "path": "/app/nae-core/import/mainImport",
        "parameters": []
    },
    {
        "id": "NAEmailsGetData",
        "method": "post",
        "path": "/app/nae-core/mails/getData",
        "parameters": []
    },
    {
        "id": "NAEmailsSend",
        "method": "post",
        "path": "/app/nae-core/mails/send",
        "parameters": []
    },
    {
        "id": "NAENotesReadAll",
        "method": "post",
        "path": "/app/nae-core/plugins/notes/readAllMessages",
        "parameters": []
    },
    {
        "id": "NAEPing",
        "method": "get",
        "path": "/app/nae-core/u/ping",
        "parameters": []
    },
    {
        "id": "NAEUMultipleListForModels",
        "method": "post",
        "path": "/app/nae-core/u/getMultipleForModel",
        "parameters": []
    },
    {
        "id": "NAEUMultipleList",
        "method": "post",
        "path": "/app/nae-core/u/getMultiple",
        "parameters": []
    },
    {
        "id": "NAEUCCaheList",
        "method": "post",
        "path": "/app/nae-core/u/getCache",
        "parameters": []
    },
    {
        "id": "NAEUListByToken",
        "method": "post",
        "path": "/app/nae-core/u/getByToken",
        "parameters": []
    },
    {
        "id": "NAEUList",
        "method": "post",
        "path": "/app/nae-core/u/get/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSave",
        "method": "post",
        "path": "/app/nae-core/u/save/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSaveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/saveMultiple",
        "parameters": []
    },
    {
        "id": "NAEURemove",
        "method": "post",
        "path": "/app/nae-core/u/remove/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEURemoveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/removeMultiple",
        "parameters": []
    },
    {
        "id": "NAEXlsxToArray",
        "method": "post",
        "path": "/app/nae-core/xlsx/toArray",
        "parameters": []
    }
]
export const NaePathsMap = {
    "get": {
        "pingtest": "/app/tmp/ping",
        "NAEPing": "/app/nae-core/u/ping"
    },
    "post": {
        "AnalyticsCustomList": "/app/plugins/AnalyticsCustom/list",
        "AnalyticsCustomListV2": "/app/plugins/AnalyticsCustom/analyticsCustomListV2",
        "DashboardAccountingCalculate": "/app/plugins/DashboardAccounting/calculate",
        "GrossCalculateV2": "/app/plugins/Dashboard/calculateV2",
        "pluginsDashboardChartStats": "/app/plugins/Dashboard/chartStats",
        "pluginsDashboardStats": "/app/plugins/Dashboard/stats",
        "pluginsDashboardSlugsGrossByOrderItems": "/app/plugins/Dashboard/getGrossByOrderItems",
        "pluginsDashboardSlugsGrossByOrderItemsDate": "/app/plugins/Dashboard/getGrossByOrderItemsDate",
        "pluginsDashboardSubsStopDateReport": "/app/plugins/Dashboard/getSubsStopDateReport",
        "DashboardDevStats": "/app/plugins/DashboardDev/stats",
        "pluginsDashboardCalcPlanPerformance": "/app/plugins/PlanPerformance/calcPlanPerformance",
        "ProductPaybackCalculate": "/app/plugins/ProductPayback/calculate",
        "ProductPerformanceCalculateV2": "/app/plugins/ProductPerformance/calculateV2",
        "ProductPerformanceRefundsData": "/app/plugins/ProductPerformance/refundsData",
        "ProductPerformanceRefundsCountData": "/app/plugins/ProductPerformance/refundsCountData",
        "RecurringControllerCalculate": "/app/plugins/Recurring/calculate",
        "SubPrefixList": "/app/plugins/SubPrefix/list",
        "KlavyioControllerGetProfile": "/app/plugins/Klavyio/getProfile",
        "KlavyioControllerReady": "/app/plugins/Klavyio/ready",
        "KlavyioControllerRun": "/app/plugins/Klavyio/run",
        "pluginsQuizResultStatExport": "/app/plugins/QuizResultStat/export",
        "pluginsReportItemResyncOrderItems": "/app/plugins/ReportItem/resyncOrderItems",
        "pluginsReportItemResyncOrderItemsForceSub": "/app/plugins/ReportItem/resyncOrderItemsForceSub",
        "pluginsReportItemResyncReportItem": "/app/plugins/ReportItem/resyncReportItem",
        "reportsFilterPeriods": "/app/plugins/Reports/ReportsFilter/periods",
        "reportsFilterSlugs": "/app/plugins/Reports/ReportsFilter/slugs",
        "reportsFilterAdSource": "/app/plugins/Reports/ReportsFilter/distintAdSources",
        "reportsFilterAdSourceLevel2": "/app/plugins/Reports/ReportsFilter/distintAdSourcesL2",
        "reportsFilterAdSourceAdopted": "/app/plugins/Reports/ReportsFilter/distintAdSourcesAdopted",
        "reportsFilterPlanSku": "/app/plugins/Reports/ReportsFilter/distintPlanSku",
        "reportsFilterLanguage": "/app/plugins/Reports/ReportsFilter/distinctLanguage",
        "SubscriptionPaymentDoRecurring": "/app/plugins/SubscriptionPayment/doRecurring",
        "AppPluginsTagCloudCarServiceCountries": "/app/plugins/Tags/countries",
        "AppPluginsTagCloudSubsCancel": "/app/plugins/Tags/subsCancel",
        "NAEauthGetProfile": "/app/nae-core/auth/get",
        "NaeConfigBuilderList": "/app/nae-core/config-builder/listConfig",
        "NaeConfigBuilderSave": "/app/nae-core/config-builder/saveConfig",
        "NaeConfigBuilderRemove": "/app/nae-core/config-builder/removeConfig",
        "NaeConfigDefaultsList": "/app/nae-core/config-defaults/listConfig",
        "NaeConfigDefaultsSave": "/app/nae-core/config-defaults/saveConfig",
        "NaeConfigDefaultsRemove": "/app/nae-core/config-defaults/removeConfig",
        "NaeConfigEditList": "/app/nae-core/config-edit/listConfig",
        "NaeConfigEditSave": "/app/nae-core/config-edit/saveConfig",
        "NaeConfigEditRemove": "/app/nae-core/config-edit/removeConfig",
        "NaeConfigTabList": "/app/nae-core/config-tabs/listConfig",
        "NaeConfigTabSave": "/app/nae-core/config-tabs/saveConfig",
        "NaeConfigTabRemove": "/app/nae-core/config-tabs/removeConfig",
        "NaeConfigViewList": "/app/nae-core/config-view/listConfig",
        "NaeConfigViewSave": "/app/nae-core/config-view/saveConfig",
        "NaeConfigViewRemove": "/app/nae-core/config-view/removeConfig",
        "NaeConfigWidgetList": "/app/nae-core/config-widgets/listConfig",
        "NaeConfigWidgetSave": "/app/nae-core/config-widgets/saveConfig",
        "NaeConfigWidgetRemove": "/app/nae-core/config-widgets/removeConfig",
        "NAEUExport": "/app/nae-core/export/doExport",
        "NAEfileRemove": "/app/nae-core/files/remove",
        "NAEfilesList": "/app/nae-core/files/list",
        "NAEfilesMultipleList": "/app/nae-core/files/multipleList",
        "NAEUImport": "/app/nae-core/import/mainImport",
        "NAEmailsGetData": "/app/nae-core/mails/getData",
        "NAEmailsSend": "/app/nae-core/mails/send",
        "NAENotesReadAll": "/app/nae-core/plugins/notes/readAllMessages",
        "NAEUMultipleListForModels": "/app/nae-core/u/getMultipleForModel",
        "NAEUMultipleList": "/app/nae-core/u/getMultiple",
        "NAEUCCaheList": "/app/nae-core/u/getCache",
        "NAEUListByToken": "/app/nae-core/u/getByToken",
        "NAEUList": "/app/nae-core/u/get/{schema}",
        "NAEUSave": "/app/nae-core/u/save/{schema}",
        "NAEUSaveMultiple": "/app/nae-core/u/saveMultiple",
        "NAEURemove": "/app/nae-core/u/remove/{schema}",
        "NAEURemoveMultiple": "/app/nae-core/u/removeMultiple",
        "NAEXlsxToArray": "/app/nae-core/xlsx/toArray"
    }
}

export const NaeApiFunctions = {
    'pingtest': () => {
        const url = '/app/tmp/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'AnalyticsCustomList': (data: any) => {
        const url = '/app/plugins/AnalyticsCustom/list';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AnalyticsCustomListV2': (data: any) => {
        const url = '/app/plugins/AnalyticsCustom/analyticsCustomListV2';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'DashboardAccountingCalculate': (data: any) => {
        const url = '/app/plugins/DashboardAccounting/calculate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'GrossCalculateV2': (data: any) => {
        const url = '/app/plugins/Dashboard/calculateV2';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardChartStats': (data: any) => {
        const url = '/app/plugins/Dashboard/chartStats';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardStats': (data: any) => {
        const url = '/app/plugins/Dashboard/stats';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardSlugsGrossByOrderItems': (data: any) => {
        const url = '/app/plugins/Dashboard/getGrossByOrderItems';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardSlugsGrossByOrderItemsDate': (data: any) => {
        const url = '/app/plugins/Dashboard/getGrossByOrderItemsDate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardSubsStopDateReport': (data: any) => {
        const url = '/app/plugins/Dashboard/getSubsStopDateReport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'DashboardDevStats': (data: any) => {
        const url = '/app/plugins/DashboardDev/stats';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsDashboardCalcPlanPerformance': (data: any) => {
        const url = '/app/plugins/PlanPerformance/calcPlanPerformance';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'ProductPaybackCalculate': (data: any) => {
        const url = '/app/plugins/ProductPayback/calculate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'ProductPerformanceCalculateV2': (data: any) => {
        const url = '/app/plugins/ProductPerformance/calculateV2';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'ProductPerformanceRefundsData': (data: any) => {
        const url = '/app/plugins/ProductPerformance/refundsData';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'ProductPerformanceRefundsCountData': (data: any) => {
        const url = '/app/plugins/ProductPerformance/refundsCountData';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'RecurringControllerCalculate': (data: any) => {
        const url = '/app/plugins/Recurring/calculate';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'SubPrefixList': (data: any) => {
        const url = '/app/plugins/SubPrefix/list';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'KlavyioControllerGetProfile': (data: any) => {
        const url = '/app/plugins/Klavyio/getProfile';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'KlavyioControllerReady': (data: any) => {
        const url = '/app/plugins/Klavyio/ready';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'KlavyioControllerRun': (data: any) => {
        const url = '/app/plugins/Klavyio/run';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsQuizResultStatExport': (data: any) => {
        const url = '/app/plugins/QuizResultStat/export';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsReportItemResyncOrderItems': (data: any) => {
        const url = '/app/plugins/ReportItem/resyncOrderItems';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsReportItemResyncOrderItemsForceSub': (data: any) => {
        const url = '/app/plugins/ReportItem/resyncOrderItemsForceSub';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsReportItemResyncReportItem': (data: any) => {
        const url = '/app/plugins/ReportItem/resyncReportItem';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterPeriods': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/periods';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterSlugs': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/slugs';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterAdSource': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/distintAdSources';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterAdSourceLevel2': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/distintAdSourcesL2';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterAdSourceAdopted': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/distintAdSourcesAdopted';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterPlanSku': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/distintPlanSku';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'reportsFilterLanguage': (data: any) => {
        const url = '/app/plugins/Reports/ReportsFilter/distinctLanguage';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'SubscriptionPaymentDoRecurring': (data: any) => {
        const url = '/app/plugins/SubscriptionPayment/doRecurring';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudCarServiceCountries': (data: any) => {
        const url = '/app/plugins/Tags/countries';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'AppPluginsTagCloudSubsCancel': (data: any) => {
        const url = '/app/plugins/Tags/subsCancel';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEauthGetProfile': (data: any) => {
        const url = '/app/nae-core/auth/get';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderList': (data: any) => {
        const url = '/app/nae-core/config-builder/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderSave': (data: any) => {
        const url = '/app/nae-core/config-builder/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderRemove': (data: any) => {
        const url = '/app/nae-core/config-builder/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsList': (data: any) => {
        const url = '/app/nae-core/config-defaults/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsSave': (data: any) => {
        const url = '/app/nae-core/config-defaults/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsRemove': (data: any) => {
        const url = '/app/nae-core/config-defaults/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditList': (data: any) => {
        const url = '/app/nae-core/config-edit/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditSave': (data: any) => {
        const url = '/app/nae-core/config-edit/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditRemove': (data: any) => {
        const url = '/app/nae-core/config-edit/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabList': (data: any) => {
        const url = '/app/nae-core/config-tabs/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabSave': (data: any) => {
        const url = '/app/nae-core/config-tabs/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabRemove': (data: any) => {
        const url = '/app/nae-core/config-tabs/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewList': (data: any) => {
        const url = '/app/nae-core/config-view/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewSave': (data: any) => {
        const url = '/app/nae-core/config-view/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewRemove': (data: any) => {
        const url = '/app/nae-core/config-view/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetList': (data: any) => {
        const url = '/app/nae-core/config-widgets/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetSave': (data: any) => {
        const url = '/app/nae-core/config-widgets/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetRemove': (data: any) => {
        const url = '/app/nae-core/config-widgets/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUExport': (data: any) => {
        const url = '/app/nae-core/export/doExport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfileRemove': (data: any) => {
        const url = '/app/nae-core/files/remove';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesList': (data: any) => {
        const url = '/app/nae-core/files/list';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesMultipleList': (data: any) => {
        const url = '/app/nae-core/files/multipleList';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUImport': (data: any) => {
        const url = '/app/nae-core/import/mainImport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsGetData': (data: any) => {
        const url = '/app/nae-core/mails/getData';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsSend': (data: any) => {
        const url = '/app/nae-core/mails/send';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAENotesReadAll': (data: any) => {
        const url = '/app/nae-core/plugins/notes/readAllMessages';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEPing': () => {
        const url = '/app/nae-core/u/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'NAEUMultipleListForModels': (data: any) => {
        const url = '/app/nae-core/u/getMultipleForModel';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUMultipleList': (data: any) => {
        const url = '/app/nae-core/u/getMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUCCaheList': (data: any) => {
        const url = '/app/nae-core/u/getCache';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUListByToken': (data: any) => {
        const url = '/app/nae-core/u/getByToken';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUList': (data: any,schema: string) => {
        const url = '/app/nae-core/u/get/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSave': (data: any,schema: string) => {
        const url = '/app/nae-core/u/save/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSaveMultiple': (data: any) => {
        const url = '/app/nae-core/u/saveMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemove': (data: any,schema: string) => {
        const url = '/app/nae-core/u/remove/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemoveMultiple': (data: any) => {
        const url = '/app/nae-core/u/removeMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEXlsxToArray': (data: any) => {
        const url = '/app/nae-core/xlsx/toArray';
        
        
                    return axiosInstance.post(url, data);
                    
    },

        }