import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import CancelSubscription from "./Customer/Toolbar/CancelSubscription";
import Refund from "./Customer/Toolbar/Refund";
import DashboardAccounting from "./Dashboard/DashboardAccounting";
import DashboardAdCosts from "./Dashboard/DashboardAdCosts";
import DashboardCancelations from "./Dashboard/Cancelations/DashboardCancelations";
import DashboardDev from "./Dashboard/DashboardDev";
import DashboardGrossV2 from "./Dashboard/DashboardGrossV2";
import DashboardMonitoring from "./Dashboard/DashboardMonitoring";
import DashboardNet from "./Dashboard/DashboardNet";
import DashboardPlanPerformance from "./Dashboard/DashboardPlanPerformance";
import DashboardProductPayback from "./Dashboard/DashboardProductPayback";
import DashboardProductPerformance2 from "./Dashboard/DashboardProductPerformance2";
import DashboardRealtime from "./Dashboard/DashboardRealtime";
import DashboardRealtimeV2 from "./Dashboard/DashboardRealtimeV2";
import DashboardRealtimeV2Custom from "./Dashboard/DashboardRealtimeV2Custom";
import DashboardRealtimeV2Newsletter from "./Dashboard/DashboardRealtimeV2Newsletter";
import DashboardRecurring from "./Customer/Recurring/DashboardRecurring";
import DashboardRefundCounts from "./Dashboard/DashboardRefundCounts";
import DashboardRefunds from "./Dashboard/DashboardRefunds";
import KlavyioStats from "./Klavyio/KlavyioStats";
import Reactivate from "./Subscription/Toolbar/Reactivate";
import DashboardRecurringPivot from "./Customer/Recurring/DashboardRecurringPivot";
import DashboardRealtimeV2CustomPivot from "./Dashboard/DashboardRealtimeV2CustomPivot";
import DashboardAdditionalSubs from "./Dashboard/DashboardAdditionalSubs";
import { KlavyioConfigSchema } from "./ConfigManagement/Schema/KlavyioConfigSchema";
import ReportItemSubscriptions from "./ReportItem/ReportItemSubscriptions/ReportItemSubscriptions";
import ReportItemShippingAddress from "./ReportItem/Shipping/ReportItemShippingAddress";
import { ReportItemSession } from "./ReportItem/Session/ReportItemSession";
import AbTestManagementForm from "./AbTestManagement/AbTestManagementForm";
import TabConfig from "./TabsConfig/TabConfig";
import SfExploreDataExport from "../Plugins/SfExploreData/SfExploreDataExport"
import SfExploreDataImport from "../Plugins/SfExploreData/SfExploreDataImport";
import MpEndPoint from "./Mp/MpEndPoint";
import AbTestVarsForm from "./AbTestManagement/AbTestVarsForm";
import DashboardGrossCustom from "./Dashboard/GrossCustom/DashboardGrossCustom";
import DashboardProductPerformanceTmp from "./Dashboard/ProductPerformanceChart/DashboardProductPerformanceTmp";
import ProductPerformanceChart from "./Dashboard/ProductPerformanceChart/ProductPerformanceChart";
import RealtimeV3 from "./Dashboard/RealtimeV3/RealtimeV3";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomViewComponentsMap,
    ...CustomListComponentsMap,


    'custom.customer.Refund': Refund,
    'custom.customer.CancelSubscription': CancelSubscription,

    'custom.subscriptions.Reactivate': Reactivate,

    'custom.customers.KlavyioStats': KlavyioStats,

    'custom.reportItem.Subscriptions': ReportItemSubscriptions,
    'custom.reportItem.ShippingAddress': ReportItemShippingAddress,

    'custom.reportItem.ReportItemSession': ReportItemSession,

    'custom.SfExploreData.Export': SfExploreDataExport,
    'custom.SfExploreData.Import': SfExploreDataImport,



    'custom.routes': {
        '/c/realtime': DashboardRealtime,
        '/c/realtime-v2': DashboardRealtimeV2,
        '/c/realtime-v3': RealtimeV3,
        '/c/realtime-v2-reports': DashboardRealtimeV2Custom,
        '/c/realtime-v2-reports-pivot': DashboardRealtimeV2CustomPivot,
        '/c/realtime-v2-newsletter': DashboardRealtimeV2Newsletter,

        '/c/dashboard-additional-subs': DashboardAdditionalSubs,

        '/c/dashboard-monitoring': DashboardMonitoring,
        '/c/dashboard-gross-v2': DashboardGrossV2,
        '/c/dashboard-net': DashboardNet,
        '/c/dashboard-product-performance-2': DashboardProductPerformance2,

        '/c/dashboard-product-performance-tmp': DashboardProductPerformanceTmp,

        '/c/dashboard-product-performance-chart': ProductPerformanceChart,

        '/c/dashboard-plan-performance': DashboardPlanPerformance,
        '/c/dashboard-product-payback': DashboardProductPayback,
        '/c/dashboard-ad-costs': DashboardAdCosts,
        '/c/dashboard-accounting': DashboardAccounting,
        '/': DashboardGrossV2,
        '/c/dashboard-dev': DashboardDev,
        '/c/dashboard-recurring': DashboardRecurring,
        '/c/dashboard-recurring-pivot': DashboardRecurringPivot,


        '/c/dashboard-refunds': DashboardRefunds,
        '/c/dashboard-refunds-count': DashboardRefundCounts,

        '/c/dashboard-cancelations': DashboardCancelations,

        '/c/abtest/:project': AbTestManagementForm,

        '/c/abvars/:project': AbTestVarsForm,

        '/c/dashboard-gross/:type': DashboardGrossCustom,

        '/c/test/:tab-config': TabConfig,

        '/mp/:dataSource': MpEndPoint,
    },
    'config': {
        'klavyio': KlavyioConfigSchema
    }
}