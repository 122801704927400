import { MenuItemWithAction } from '@newageerp/v3.bundles.modal-bundle'
import React, { Fragment } from 'react'
type Props = {
    id: number
}
export default function KlavyioEventSync(props: Props) {
    return (
        <Fragment>
            <MenuItemWithAction
                elementId={props.id}
                action='KlavyioControllerReady'
                children='Ready'
            />
            <MenuItemWithAction
                elementId={props.id}
                action='KlavyioControllerRun'
                children='Run'
            />
        </Fragment>
    )
}
